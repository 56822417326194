#edit-invoice-modal .modal {
    transition: opacity 0.15s linear;
    background: rgba(0, 0, 0, 0.5);
  }
  
  #edit-invoice-modal .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
  }
  
  #edit-invoice-modal .modal.show .modal-dialog {
    transform: translate(0, 0);
  }
  
  #edit-invoice-modal .modal-content {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
  }
  
  #edit-invoice-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  #edit-invoice-modal .modal-title {
    margin: 0;
    line-height: 1.5;
  }
  
  #edit-invoice-modal .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  #edit-invoice-modal .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
  }
  
  #edit-invoice-modal .modal {
    transition: opacity 0.15s linear;
    background: rgba(0, 0, 0, 0.5);
  }
  
  #edit-invoice-modal .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
  }
  
  #edit-invoice-modal .modal.show .modal-dialog {
    transform: translate(0, 0);
  }
  
  #edit-invoice-modal .modal-content {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
  }
  
  #edit-invoice-modal .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  #edit-invoice-modal .modal-title {
    margin: 0;
    line-height: 1.5;
  }
  
  #edit-invoice-modal .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    max-height: 400px;  /* Fixed height for the modal body */
    overflow-y: auto;   /* Enable vertical scrolling */
  }
  
  #edit-invoice-modal .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
  }
  